<template>
  <div class="container">
    <div class="form">
      <img src="@/assets/images/logo-artman.jpg">
      <el-form>
        <el-form-item>
          <el-input
            v-model="form.code"
            :maxlength="5"
            :error="errorCode"
            class="form-input-code"
            placeholder="Enter your code"
            @change="validate"
          />
        </el-form-item>

        <el-form-item v-show="form.code && form.code.length > 4">
          <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="Choose the date"
            prefix-icon="null"
            format="dd-MM-yyyy"
            value-format="dd-MM-yyyy"
            :error="errorDate"
            @change="validate"
          />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Message } from 'element-ui'
export default {
  name: 'Home',
  data () {
    return {
      form: {
        code: this.$route.query.code,
        date: this.$route.query.date
      },
      errorCode: null,
      errorDate: null
    }
  },
  mounted () {
    if (this.form.code && this.form.date) {
      this.validate()
    }
  },
  methods: {
    validate () {
      if (!this.form.date || !this.form.code) {
        return
      }
      axios.post('client/shooting', this.form)
        .then((code) =>
          this.$router.push('/shooting/' + code.data)
        )
        .catch((err) => {
          if (err.toString().match(/412/)) {
            Message({
              message: 'This shooting is currently locked.',
              duration: 10000
            })
          } else if (err.toString().match(/409/)) {
            Message({
              message: 'Your photos are being prepared, we are so very excited for the end result which we are working diligently towards and know you’ll be thrilled.',
              duration: 10000
            })
          } else {
            Message.error('Please verify your code AND/OR the date. Contact-us in case of problem.')
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    height: calc(100vh - 87px);
  }

  h1 {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 400;
    font-size:46px;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }

  .form p {
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 22px;
    margin-top: 5px;
  }

  .el-form {
    width: 400px;
    margin: 0 auto;
  }

  .el-date-editor {
    width: 400px !important;
  }

  .el-input__icon.el-icon-date {
    display: none !important;
  }

  .el-button {
    width: 400px;
  }

  .el-form-item, .el-button--primary{
    transition: all ease-in-out .3s;
  }

  @media screen and (max-width:410px) {
    .container {
      overflow: hidden
    }

    .home-page {
      .form {
        max-width: 100%;

        .el-form {
          max-width: 90%;

          .el-form-item {
            max-width: 100%;

            .el-date-editor  {
              max-width: 100%;
            }
          }
        }

        h1 {
          font-size: 2.6rem;
        }
      }
    }
  }
</style>

<style lang="scss">
  .form {
    .el-input__inner {
      border: 2px solid #ffffff!important;
      border-radius: 0px !important;
      height: 60px !important;
      font-size: 22px !important;
      text-align: center !important;
      color: #ffffff!important;
      background-color: transparent!important;

      &::placeholder {
        color: white!important;
      }
    }
  }

  .form-input-code input {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 4px;
  }
</style>
