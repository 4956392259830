<template>
  <el-main v-loading="!shooting.id || loading">
    <el-row :gutter="10">
      <el-col :span="8">
        <el-button
          @click="$router.push('/admin')"
          icon="el-icon-back"
          size="small"
        >
          Back to the list
        </el-button>

        <el-button
          v-if="(shooting.photos && shooting.photos.length > 0) || shooting"
          size="small"
          type="primary"
          @click="notify"
        >
          NOTIFY THE CLIENT
        </el-button>
      </el-col>

      <el-col :span="8" style="text-align: center">
        <h3 style="margin-top: 0">{{ shooting.photos ? shooting.photos.length : 0 }} photos</h3>
      </el-col>

      <el-col :span="8">
        <div class="access-container" style="float: right">
          <el-tag v-if="shooting.access" type="success">Connection allowed</el-tag>
          <el-tag v-else type="danger">Connection forbidden</el-tag>
        </div>
      </el-col>
    </el-row>

    <el-alert
      type="info"
      :title="notification"
      :closable="false"
      show-icon
    />

    <el-card>
      <History v-if="shooting.logs" :open.sync="history" :logs="shooting.logs" title="Login history" />

      <el-descriptions>
        <el-button
          slot="title"
          icon="el-icon-edit"
          type="text"
          @click="edit = true"
        >
          {{ shooting.identifier || 'Edit' }}
        </el-button>

        <el-descriptions-item label="Date / Code">{{ shooting.date }} / <strong>{{ shooting.id.substr(-5).toUpperCase() }}</strong></el-descriptions-item>
        <el-descriptions-item label="Primary Email">{{ shooting.email }}</el-descriptions-item>
        <el-descriptions-item label="Phone">+{{ shooting.phonePrefix }}{{ shooting.phone }}</el-descriptions-item>
        <el-descriptions-item label="Status">
          <el-tag size="small" :type="getStatusType(shooting.status)">
            {{ getStatus(shooting.status) }}
          </el-tag>
          <template v-if="(shooting.logs && shooting.logs.length > 0)">- <span @click="history = true">See connection history</span></template>
        </el-descriptions-item>
        <el-descriptions-item label="Other emails">{{ (shooting.emailCc || []).join(', ') }}</el-descriptions-item>
      </el-descriptions>
    </el-card>

    <div class="content">
      <div v-if="shooting.status === 'P'" class="upload-container">
        <el-upload class="upload" drag multiple :action="action" :headers="headers" :on-success="successUpload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            jpg/png files
          </div>
        </el-upload>
      </div>
      <div class="photos-container">
        <masonry :cols="{ default: 4, 1760: 3, 1410: 2, 1060: 1 }" :gutter="15">
          <div
            class="masonry-img-container"
            v-for="photo in shooting.photos"
            :key="photo.id"
          >
            <el-image
              :src="getPhotoPath(shooting.id, photo.id)"
              lazy
            />
            <i v-if="shooting.status === 'P'" class="el-icon-error" @click="deletePhoto(photo.id)"></i>
          </div>
        </masonry>
      </div>
    </div>

    <el-dialog :visible.sync="edit" :title="$root.locations[location].name" top="2vh">
      <AdminEdit :shooting="shooting" @save="save" />
    </el-dialog>
  </el-main>
</template>

<script>
import mixin from '@/mixins'
import axios from 'axios'
import moment from 'moment'
import History from '@/components/history'
import { mapState } from 'vuex'
import AdminEdit from '../../components/Create.vue'

export default {
  mixins: [mixin],
  components: {
    History,
    AdminEdit
  },
  data () {
    return {
      shooting: {},
      history: false,
      loading: false,
      edit: false,
      headers: axios.defaults.headers.common
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    action () {
      return axios.defaults.baseURL + 'admin/shootings/' + this.shooting.id + '/upload'
    },
    notification () {
      return this.shooting && this.shooting.notified ? 'Client has been notified ' +
        (this.shooting.notified > 2022 ? moment.unix(this.shooting.notified).calendar() : '') : false
    },
    ...mapState(['location', 'token'])
  },
  methods: {
    load () {
      this.loading = true
      axios.get('admin/shootings/' + this.$route.params.id).then((res) => {
        this.shooting = res.data
        this.loading = false
      })
    },
    successUpload (photo) {
      this.shooting.photos.unshift(photo)
    },
    deletePhoto (photoId) {
      this.$confirm('This will permanently delete the photo. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        conformButtonType: 'error',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => axios.delete('admin/shootings/' + this.$route.params.id + '/' + photoId))
        .then(() => {
          this.shooting.photos = this.shooting.photos.filter((photo) => photo.id !== photoId)
        })
        .catch((err) => console.error(err))
    },
    notify () {
      this.loading = true
      this.$confirm('Please note that you CAN\'T upload additional photos... Continue?', 'Notify the client that photos are READY', {
        confirmButtonText: 'Yes, notify and lock portfolio',
        confirmButtonType: 'error',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => axios.post('admin/shootings/' + this.$route.params.id + '/notify'))
        .then((res) => {
          this.shooting = res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    save (shooting) {
      this.edit = false
      this.loading = true
      axios.put('admin/shootings/' + this.shooting.id, shooting)
        .then((res) => {
          this.shooting = res.data
          this.loading = false
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .upload {
      width: 360px;
    }
  }

  .el-alert {
    margin: 10px 0;
  }

  .container {
    margin-top: 30px;
    padding: 0 8px;
  }

  .el-descriptions {
    .el-button {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
  }
  .content {
    width: 99%;
    max-width: 1300px;
    margin: 0 auto 40px;
  }

  .photos-container {
    margin-top: 40px;

    .masonry-img-container {
      margin-bottom: 11px;
      position: relative;

      .el-icon-error {
        opacity: 0;
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 22px;
        transition: all .3s ease-in-out;
      }
      .el-image {
        transition: all .3s ease-in-out;
      }
    }
  }

  .admin .masonry-img-container:hover {
    .el-image {
      transition: all .3s ease-in-out;
      opacity: .3;
    }
    .el-icon-error {
      cursor: pointer;
      opacity: 1;
      transition: all .3s ease-in-out;
    }
  }

  @media screen and (max-width: 530px){
    h1 {
      margin-bottom: 46px;
    }
  }
</style>

<style lang="scss">
  .upload {
    width: 100%!important;
    .el-upload {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
      }
    }
  }

  .el-table__row.danger {
    background-color: #ffecec;
  }
  .el-table__row.success {
    background-color: #ecfff0;

    .el-button.el-button--text {
      color: #444;
    }
  }
  .el-table__row {
    .el-button.el-button--text {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 530px){
    .el-message-box {
      max-width:92%
    }
  }
</style>
