<template>
  <div id="app" :class="isHomepage">
    <router-view />

    <footer :class="isHomepage">
      <p>For support, email <a href="mailto:linda@artman-agency.com">linda@artman-agency.com</a> or call us on: +49 170 8510 441 </p>
    </footer>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  computed: {
    isHomepage () {
      return this.$route.name === 'Home' ? 'home-page' : ''
    }
  },
  created () {
    this.$store.commit('init')
  }
}
</script>

<style lang="scss">
  #app, html, body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    position: relative;
  }

  #app.home-page {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)),
      url('./assets/images/pearl-beach-hotel.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  footer {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    padding: 15px;
    p {
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 1px;
    }
  }

  footer.home-page {
    display: block;
  }

  @media screen and (max-width:768px) {
    #app.home-page {
      background-image: linear-gradient(rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.4)),
      url('./assets/images/pearl-beach-hotel.jpg');
    }
  }
</style>
