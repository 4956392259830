<template>
  <el-main v-loading="!folder.id || loading">
    <el-row :gutter="10">
      <el-col :span="8">
        <el-button
          @click="$router.push('/admin/folders')"
          icon="el-icon-back"
          size="small"
        >
          Back to the list
        </el-button>

        <el-button
          v-if="(folder.photos && folder.photos.length > 0) && folder.status === 'P'"
          size="small"
          type="primary"
          @click="build"
        >
          COMPRESS ALL PHOTOS INTO ZIP
        </el-button>

        <a
          v-else-if="folder.status === 'B'"
          class="download"
          :href="getPrivateArchivePath(folder.id)"
        >
          <i class="el-icon-download" />
          DOWNLOAD ALL PHOTOS
        </a>
      </el-col>

      <el-col :span="8" style="text-align: center">
        <h3 style="margin-top: 0">{{ folder.photos ? folder.photos.length : 0 }} photos</h3>
      </el-col>
    </el-row>

    <el-card>
      <el-descriptions>
        <el-button
          slot="title"
          icon="el-icon-edit"
          type="text"
          @click="edit = true"
        >
          {{ folder.identifier || 'Edit' }}
        </el-button>

        <el-descriptions-item label="Date">{{ folder.date }}</el-descriptions-item>
        <el-descriptions-item label="Status">
          <el-tag size="small" :type="getStatusType(folder.status)">
            {{ getStatus(folder.status) }}
          </el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>

    <div class="content">
      <div class="upload-container">
        <el-upload class="upload" drag multiple :action="action" :headers="headers" :on-success="successUpload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            jpg/png files
          </div>
        </el-upload>
      </div>
      <div class="photos-container">
        <masonry :cols="{ default: 5, 1760: 4, 1410: 3, 1060: 2 }" :gutter="15">
          <div
            class="masonry-img-container"
            v-for="photo in folder.photos"
            :key="photo.id"
          >
            <el-image
              :src="getPhotoPath(folder.id, photo.id)"
              lazy
            />
            <i v-if="folder.status === 'P'" class="el-icon-error" @click="deletePhoto(photo.id)"></i>
          </div>
        </masonry>
      </div>
    </div>

    <el-dialog :visible.sync="edit" top="2vh">
      <AdminEdit :folder="folder" :is-public="false" @save="save" />
    </el-dialog>
  </el-main>
</template>

<script>
import mixin from '@/mixins'
import axios from 'axios'
import { mapState } from 'vuex'
import AdminEdit from '../../components/Create.vue'

export default {
  mixins: [mixin],
  components: {
    AdminEdit
  },
  data () {
    return {
      folder: {},
      loading: false,
      edit: false,
      headers: axios.defaults.headers.common
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    action () {
      return axios.defaults.baseURL + 'admin/folders/' + this.folder.id + '/upload'
    },
    ...mapState(['token'])
  },
  methods: {
    load () {
      this.loading = true
      axios.get('admin/folders/' + this.$route.params.id).then((res) => {
        this.folder = res.data
        this.loading = false
      })
    },
    successUpload (photo) {
      this.folder.photos.unshift(photo)
      this.folder.status = 'P'
    },
    deletePhoto (photoId) {
      this.$confirm('This will permanently delete the photo. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        conformButtonType: 'error',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => axios.delete('admin/folders/' + this.$route.params.id + '/' + photoId))
        .then(() => {
          this.folder.photos = this.folder.photos.filter((photo) => photo.id !== photoId)
          this.folder.status = 'P'
        })
        .catch((err) => console.error(err))
    },
    build () {
      this.loading = true
      this.$confirm('Continue?', 'Compress all photos into a NEW zip', {
        confirmButtonText: 'Yes',
        confirmButtonType: 'error',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => axios.post('admin/folders/' + this.$route.params.id + '/build'))
        .then((res) => {
          this.folder = res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    save (folder) {
      this.edit = false
      this.loading = true
      axios.put('admin/folders/' + this.folder.id, folder)
        .then((res) => {
          this.folder = res.data
          this.loading = false
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .upload {
      width: 360px;
    }
  }

  .el-alert {
    margin: 10px 0;
  }

  .container {
    margin-top: 30px;
    padding: 0 8px;
  }

  .el-descriptions {
    .el-button {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
  }
  .content {
    width: 99%;
    max-width: 1300px;
    margin: 0 auto 40px;
  }

  .photos-container {
    margin-top: 40px;

    .masonry-img-container {
      margin-bottom: 11px;
      position: relative;

      .el-icon-error {
        opacity: 0;
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 22px;
        transition: all .3s ease-in-out;
      }
      .el-image {
        transition: all .3s ease-in-out;
      }
    }
  }

  .admin .masonry-img-container:hover {
    .el-image {
      transition: all .3s ease-in-out;
      opacity: .3;
    }
    .el-icon-error {
      cursor: pointer;
      opacity: 1;
      transition: all .3s ease-in-out;
    }
  }

  .download {
    color: #444;
    margin-left: 25px;
    position: relative;
    padding-left: 20px;
    text-decoration: none;

    i {
      position: absolute;
      left: 0;
      top: 3px;
    }
  }

  @media screen and (max-width: 530px){
    h1 {
      margin-bottom: 46px;
    }
  }
</style>

<style lang="scss">
  .upload {
    width: 100%!important;
    .el-upload {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
      }
    }
  }

  .el-table__row.danger {
    background-color: #ffecec;
  }
  .el-table__row.success {
    background-color: #ecfff0;

    .el-button.el-button--text {
      color: #444;
    }
  }
  .el-table__row {
    .el-button.el-button--text {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 530px){
    .el-message-box {
      max-width:92%
    }
  }
</style>
