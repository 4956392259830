import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './element.scss'
import locale from 'element-ui/lib/locale/lang/en'
import VueMasonry from 'vue-masonry-css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
Vue.use(VueMasonry)
Vue.use(VueCookies)
// Vue.$cookies.config('7d')

Vue.use(VueAxios, axios)
// Vue.axios.defaults.baseURL = 'http://localhost:9915/'
Vue.axios.defaults.baseURL = ''

new Vue({
  router,
  store,
  data () {
    return {
      locations: {
        STZ: { name: 'Saint-Tropez', description: 'Nikki Beach Club Saint-Tropez' },
        SBT: { name: 'Saint-Barthélemy', description: 'Nikki Beach Club Saint-Barth' }
      }
    }
  },
  render: h => h(App)
}).$mount('#app')
