import axios from 'axios'

export default {
  data () {
    return {
      status: {
        S: 'Not logged in yet',
        O: 'Client connected',
        B: 'Build up to date',
        P: 'Waiting for photos to be uploaded'
      }
    }
  },
  methods: {
    getStatus (status) {
      return this.status[status] || ''
    },
    getStatusType (status) {
      if (status === 'S') {
        return 'danger'
      } else if (status === 'O' || status === 'B') {
        return 'success'
      }
      return 'warning'
    },
    getPhotoPath (shootingId, photoId) {
      return axios.defaults.baseURL + '/mini/' + shootingId + '/' + photoId + '.jpg'
    },
    getArchivePath (shootingId) {
      return axios.defaults.baseURL + '/source/' + shootingId + '/artman-photos.zip'
    },
    getPrivateArchivePath (folderId) {
      return axios.defaults.baseURL + '/source/' + folderId + '/artman-folder.zip'
    }
  }
}
