import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
  state: () => ({
    location: 'STZ',
    token: localStorage.getItem('token'),
    user: null
  }),
  mutations: {
    setLocation (state, data) {
      state.location = data
      Vue.$cookies.set('location', state.location)
    },
    setToken (state, token) {
      state.token = token
    },
    setUser (state, user) {
      state.user = user
    },
    logout (state) {
      state.token = null
      state.user = null
      delete axios.defaults.headers.common.Authorization
      localStorage.clear()
    },
    init (state) {
      if (Vue.$cookies.get('location')) {
        state.location = Vue.$cookies.get('location')
      }
    }
  },
  actions: {
    getUser ({ commit }) {
      return axios.get('admin/me').then((res) => commit('setUser', res.data)).catch(() => commit('logout'))
    },
    async auth ({ commit, state, dispatch }) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + state.token
      localStorage.setItem('token', state.token)

      try {
        await dispatch('getUser')
      } catch (err) {
        return commit('logout')
      }
    }
  }
})
