<template>
  <div class="container">
    <div class="content">
      <el-form ref="login" :model="form" :rules="rules">
        <el-form-item label="Access Login" prop="login" :error="error">
          <el-input v-model="form.login" />
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input v-model="form.password" type="password" />
        </el-form-item>
        <el-button type="primary" :loading="loading" @click.prevent="connect">Connect</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      loading: false,
      error: null,
      form: {
        login: '',
        password: ''
      },
      rules: {
        login: [{
          required: true,
          message: 'Please enter your login',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: 'Please enter your password',
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    connect () {
      this.error = null
      this.$refs.login.validate((valid) => {
        if (valid) {
          this.loading = true
          axios.post('admin/login', this.form)
            .then((res) => {
              this.$store.commit('setToken', res.data)
              return this.$store.dispatch('auth')
            })
            .then(() => this.$router.push('/admin'))
            .catch(() => {
              this.loading = false
              this.error = 'Invalid login/password.'
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 30px;
  padding: 0 8px;
}
.el-form {
  max-width: 400px;
  margin: 30px auto;
}
</style>
