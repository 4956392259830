<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    append-to-body
  >
    <el-table :data="logs" :default-sort="{ order:'descending', prop: 'date' }">
      <el-table-column prop="ip" label="Ip" />
      <el-table-column prop="date" label="Date">
        <template slot-scope="scope">
          {{ formatedDate(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column label="Time">
        <template slot-scope="scope">
          {{ formatedTime(scope.row.date) }}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import moment from 'moment-timezone'
export default {
  props: {
    logs: {
      type: Array,
      default: () => []
    },
    open: Boolean,
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false
    }
  },
  watch: {
    open (value) {
      this.visible = value
      this.loading = false
    },
    visible (value) {
      this.$nextTick(() => this.$emit('update:open', value))
    }
  },
  methods: {
    formatedDate (date) {
      return moment(date).format('DD/MM/yyyy')
    },
    formatedTime (date) {
      return moment(date).format('LTS')
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
