<template>
  <div class="container admin-create">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="form" class="create-form">
        <el-form-item prop="date">
          <el-date-picker
            v-model="form.date"
            format="dd-MM-yyyy"
            value-format="dd-MM-yyyy"
            type="date"
            placeholder="Choose a date"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="identifier">
          <el-input v-model="form.identifier" placeholder="Name" />
          <small v-if="isPublic">This name will appear on client portfolio.</small>
        </el-form-item>

        <template v-if="isPublic">
          <el-form-item prop="email" :error="errorEmail">
            <el-input v-model="form.email" placeholder="Primary email" />
            <el-input
              v-for="cc in form.emailCc"
              :key="cc.k"
              placeholder="Other emails"
              v-model="cc.v"
            />

            <el-button
              icon="el-icon-plus"
              type="default"
              @click="form.emailCc.push({ k: Date.now(), v: '' })"
            >
              Add others email addresses
            </el-button>
          </el-form-item>

          <el-form-item prop="phone">
            <el-input placeholder="Phone number" v-model="form.phone" class="input-with-select">
            <el-select v-model="form.phonePrefix" slot="prepend" placeholder="Country" filterable>
              <el-option
                v-for="(country, index) in countries"
                :key="country.phone + index"
                :value="country.phone"
                :label="country.name +' (+' + country.phone + ')'"
              >
                {{ country.name }} (+{{ country.phone }})
              </el-option>
            </el-select>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-switch v-model="form.access" />
            <span class="switch-label">Allow client to connect.</span>
          </el-form-item>

          <el-form-item v-if="shooting.id">
            <el-switch v-model="form.notify" />
            <span class="switch-label">Send a notification to the client.</span>
          </el-form-item>
        </template>

        <el-form-item>
          <el-button
            v-if="shooting.id || folder.id"
            type="primary"
            @click="save"
          >
            Save changes
          </el-button>

          <el-button
            v-else
            type="primary"
            :loading="loading"
            @click="create"
          >
            Create
          </el-button>
        </el-form-item>
      </el-form>

      <p v-if="shooting.id" class="delete">
        <el-button type="text" @click.prevent="remove">
          Remove definitively
        </el-button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import { countries } from 'countries-list'
import { isEmail } from 'validator'
export default {
  props: {
    shooting: {
      type: Object,
      default: () => ({})
    },
    folder: {
      type: Object,
      default: () => ({})
    },
    isPublic: {
      type: Boolean,
      default: true
    }
  },
  name: 'AdminCreate',
  data () {
    return {
      loading: false,
      errorEmail: null,
      form: {
        identifier: null,
        email: null,
        emailCc: [],
        phone: null,
        phonePrefix: '33',
        date: moment().format('DD-MM-YYYY'),
        location: null,
        notify: true,
        access: true
      },
      rules: {
        date: [
          {
            required: true,
            message: 'Please add shooting date',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: 'Please add email',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: 'Please add valid email',
            trigger: 'blur'
          }
        ],
        identifier: [
          {
            required: true,
            message: 'Please add identifier',
            trigger: 'blur'
          },
          {
            min: 5,
            message: 'Min 5 characters',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: false,
            message: 'Please add phone number',
            trigger: 'change'
          },
          {
            min: 5,
            max: 20,
            message: 'Length should be 5 to 20',
            trigger: 'blur'
          }
        ]
      },
      countries
    }
  },
  computed: mapState(['location']),
  watch: {
    shooting: {
      handler (value) {
        this.form.identifier = value.identifier
        this.form.email = value.email
        this.form.emailCc = (value.emailCc || []).map((v) => ({ k: Date.now(), v }))
        this.form.phone = value.phone
        this.form.phonePrefix = value.phonePrefix || '33'
        this.form.date = value.date
        this.form.location = value.location
        this.form.notify = value.notify
        this.form.access = value.access
      },
      deep: true,
      immediate: true
    },
    folder: {
      handler (value) {
        this.form.identifier = value.identifier
        this.form.date = value.date
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    save () {
      if (this.isPublic) {
        this.errorEmail = null
        this.form.emailCc = this.form.emailCc.filter((obj) => (obj.v || '').trim())
      }

      this.$refs.form.validate((valid) => {
        if (this.isPublic) {
          const failed = this.form.emailCc.find((obj) => !isEmail(obj.v))
          if (failed) {
            this.errorEmail = 'Please add valid email'
            return
          }
        }

        if (valid) {
          this.$emit('save', {
            ...this.form,
            emailCc: this.isPublic ? this.form.emailCc.map((obj) => obj.v.trim()) : undefined
          })
        }
      })
    },
    create () {
      if (this.isPublic) {
        this.errorEmail = null
        this.form.emailCc = this.form.emailCc.filter((obj) => (obj.v || '').trim())
      }

      this.$refs.form.validate((valid) => {
        if (this.isPublic) {
          const failed = this.form.emailCc.find((obj) => !isEmail(obj.v))
          if (failed) {
            this.errorEmail = 'Please add valid email'
            return
          }
        }

        if (valid) {
          this.loading = true

          if (this.isPublic) {
            axios.post('admin/shootings', {
              ...this.form,
              emailCc: this.form.emailCc.map((obj) => obj.v.trim()),
              location: this.location
            }).then((res) => {
              this.loading = false
              this.$router.push({
                name: 'AdminView',
                params: { id: res.data.id }
              })
              this.$emit('close')
            }).catch((e) => console.log(e.message))
          } else {
            axios.post('admin/folders', this.form).then((res) => {
              this.loading = false
              this.$router.push({
                name: 'AdminPrivateView',
                params: { id: res.data.id }
              })
              this.$emit('close')
            }).catch((e) => console.log(e.message))
          }
        }
      })
    },
    remove () {
      const msg = 'Once you remove, there is no going back. To confirm, please enter REMOVE below.'
      this.loading = true
      this.$prompt(msg, 'All photos will be archived !', {
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'el-button--danger',
        inputValidator: (value) => value === 'REMOVE',
        inputErrorMessage: 'Invalid input'
      }).then(() => axios.delete('admin/' + (this.isPublic ? 'shootings' : 'folders') + '/' + this.shooting.id))
        .then(() => this.$router.push('/admin'))
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-input + .el-button {
  margin-top: 4px;
}
.container {
  padding: 0 8px;
  margin-bottom: 50px;
}
.switch-label {
  margin-left: 5px;
}
.content {
  width: 99%;
  max-width: 1300px;
  margin: 0px auto;
  .el-form {
    max-width: 600px;
    margin: 0 auto;
    .el-button {
      width: 100%;
    }
  }
}
.create-form {
  width: 350px;
  .el-date-editor {
    width: 100%;
  }
}
.delete {
  text-align: center;
}
</style>

<style lang="scss">
  .create-form {
    .el-select > .el-input {
      width: 130px!important;
    }
  }
</style>
