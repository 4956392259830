<template>
  <div class="container">
    <h1>Contact</h1>
    <el-form :model="form" label-position="top" :rules="rules" ref="form">

      <el-form-item label="Name" prop="name">
        <el-input v-model="form.name"/>
      </el-form-item>

      <el-form-item label="Email" prop="email">
        <el-input v-model="form.email"/>
      </el-form-item>

      <el-form-item label="Message" prop="message">
        <el-input
          type="textarea"
          :rows="4"
          v-model="form.message">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('form')"
          :loading="loading"
        >
          Send
        </el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      form: {
        name: null,
        email: null,
        message: null
      },
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Please add your name', trigger: 'blur' }
        ],
        email: [
          {
            required: true,
            message: 'Please add your email',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: 'Please add valid email',
            trigger: 'blur'
          }
        ],
        message: [
          { required: true, message: 'Please add your message', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          axios.post('client/contact', this.form)
            .then(() => {
              this.$message({
                message: 'Message send thank you',
                type: 'success'
              })
            })
            .then(() => { this.loading = false })
            .then(() => {
              this.$refs[formName].resetFields()
            })
            .catch(() => {
              this.$message({
                message: 'oops an error occured',
                type: 'error'
              })
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 1300px;
  margin: 0 auto;
  h1 {
    text-align: center;
  }
  .el-form {
    max-width: 500px;
    margin: 0 auto;
  }
}

</style>
