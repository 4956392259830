<template>
  <el-container v-loading="!user">
    <el-header style="height: 90px">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-menu
            :default-active="$route.path"
            background-color="#000"
            text-color="#fff"
            active-text-color="#cc0a0d"
            mode="horizontal"
            router
          >
            <el-menu-item index="/admin">Shootings</el-menu-item>
            <el-menu-item v-if="user.admin" index="/admin/folders">Private Folders</el-menu-item>
            <el-menu-item v-if="user.admin" index="/admin/users">Users</el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="8">
          <img src="../../assets/images/logo-artman.jpg">
        </el-col>
        <el-col :span="8">
          <el-button icon="el-icon-switch-button" @click="logout">{{ user.firstName }}{{ user.lastName }}</el-button>
        </el-col>
      </el-row>
    </el-header>

    <router-view v-if="$route.name !== 'Admin'" />

    <el-main v-else>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-input
            v-model="search"
            prefix-icon="el-icon-search"
            placeholder="Search something"
            clearable
          />
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-select v-model="localLocation">
            <el-option
              v-for="(value, key) in $root.locations"
              :key="key"
              :value="key"
              :label="value.name"
            >
              {{ value.name }}
            </el-option>
          </el-select>

          <el-button
            type="primary"
            class="button-create"
            icon="el-icon-plus"
            @click="create = true"
          >
            Create shooting
          </el-button>
        </el-col>
      </el-row>

      <div class="filters">
        <el-checkbox v-model="pending" label="Waiting for Upload" border />
        <el-checkbox v-model="notified" label="Not logged in yet" border />
      </div>

      <el-table
        v-loading="loading"
        :data="data"
        :default-sort="{prop: 'date', order: 'descending'}"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="date" label="Date" sortable class="mobile" width="100">
          <template slot-scope="scope">
            {{ formatedDate(scope.row.date) }}
          </template>
        </el-table-column>

        <el-table-column label="Photos" align="center" sortable prop="photos" v-if="!isTablette" width="150">
          <template slot-scope="scope">
            {{ scope.row.photos }}
          </template>
        </el-table-column>

        <el-table-column label="Name">
          <template slot-scope="scope">
            <el-button type="text" @click.prevent="$router.push({ name: 'AdminView', params: { id: scope.row.id } })">
              {{ scope.row.identifier }}
            </el-button>
          </template>
        </el-table-column>

        <el-table-column v-if="!isTablette" width="250" align="right">
          <template slot-scope="scope">
            <el-tag :type="getStatusType(scope.row.status)">
              {{ getStatus(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-main>

    <el-dialog :visible.sync="create" :title="$root.locations[location].name" top="2vh">
      <AdminCreate @close="close" />
    </el-dialog>
  </el-container>
</template>

<script>
import mixin from '@/mixins'
import moment from 'moment-timezone'
import axios from 'axios'
import { mapState } from 'vuex'
import AdminCreate from '../../components/Create.vue'

export default {
  mixins: [mixin],
  components: { AdminCreate },
  name: 'Admin',
  data () {
    return {
      shootings: [],
      localLocation: null,
      search: null,
      pending: false,
      notified: false,
      create: false,
      loading: false
    }
  },
  computed: {
    isMobile () {
      return window.screen.width < 430
    },
    isTablette () {
      return window.screen.width < 766
    },
    data () {
      if (this.search) {
        const regex = new RegExp(this.search, 'i')
        return this.shootings.filter((s) => s.identifier.match(regex) || s.email.match(regex))
      }
      if (this.pending || this.notified) {
        return this.shootings.filter((s) => (s.status === 'P' && this.pending) || (s.status === 'S' && this.notified))
      }
      return this.shootings
    },
    ...mapState(['location', 'token', 'user'])
  },
  mounted () {
    this.localLocation = this.location || null
    this.load()
  },
  methods: {
    load () {
      this.loading = true
      axios.get('admin/shootings?location=' + this.location).then((shootings) => {
        this.shootings = shootings.data
        this.loading = false
      }).catch(() => this.$router.push('/login'))
    },
    close () {
      this.create = false
      this.load()
    },
    formatedDate (date) {
      return moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY')
    },
    formatedNotifyDate (ts) {
      return moment.unix(ts).format('DD/MM/YYYY LT')
    },
    tableRowClassName ({ row }) {
      return this.getStatusType(row.status)
    },
    logout () {
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  },
  watch: {
    '$route.name' () {
      this.load()
    },
    localLocation (value) {
      this.$store.commit('setLocation', value)
      this.load()
    }
  },
  beforeMount () {
    if (this.token) {
      this.$store.dispatch('auth')
    } else {
      this.$router.push('/login')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../theme.scss";

.el-header {
  background-color: #000;

  .el-menu {
    border-bottom: 0;
    height: 90px;

    .el-menu-item {
      height: 90px;
      line-height: 90px;
      font-size: 20px;

      &.is-active {
        background-color: $--color-primary !important;
        color: #fff !important;
      }
    }
  }
  .el-row {
    .el-col {
      text-align: center;

      img {
        max-height: 90px;
      }

      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;

        .el-button {
          margin-top: 25px
        }
      }
    }
  }
}

.el-main {
  .el-select + .el-button {
    margin-left: 10px;
  }
}

.filters {
  margin-top: 20px;
}

@media screen and (max-width: 765px){
}
</style>
