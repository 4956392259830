<template>
  <el-container v-loading="loading" class="user">
    <el-aside v-if="shooting" width="300px">
      <img src="@/assets/images/logo-artman.jpg" class="logo" @click="changeView(true, index, null)">

      <h2>{{ shooting.identifier || shooting.email }}</h2>
      <h3>{{ $root.locations[shooting.location].description }}</h3>

      <div class="button-container">
        <a :href="getArchivePath(shooting.id)" type="text" class="button--primary button-big download">Download all photos</a>
        <a href="mailto:linda@artman-agency.com" type="text" class="button--secondary button-big">Contact</a>
      </div>

      <div class="single-buttons">
        <span class="single-buttons__date">{{ formatedDate }}</span>
        <div v-if="single">
          <div class="single-photo-nav">
            <el-button type="text" :disabled="index === 0" @click="index--" class="prev">prev</el-button>
            <el-button type="text" :disabled="index === (shooting.photos.length - 1)" @click="index++" class="next" >next</el-button>
          </div>
          <el-button type="text" class="single-buttons__show-all" @click="changeView(single, index, null)">Show thumbnails</el-button>
        </div>
      </div>
    </el-aside>

    <el-container>
      <!--
      <el-main class="text-center">
        <p>We are thrilled to send you the final product of your photographs and hope to see you again soon to share many more memories together.</p>
      </el-main>-->

      <el-main v-if="shooting" class="shootings">
        <div v-if="single" class="single-img-container">
          <el-image :src="getPhotoPath(shooting.id, shooting.photos[index].id)" lazy fit="scale-down" />
        </div>

        <masonry v-else :cols="{ default: 4, 1760: 3, 1410: 2, 1060: 1 }" :gutter="15">
          <div class="masonry-img-container" v-for="(photo, index) in shooting.photos" :key="photo.id">
            <el-image
              :src="getPhotoPath(shooting.id, photo.id)"
              :style="{ cursor: 'pointer' }"
              lazy
              @click="changeView(single, index, { shooting, photo })"
            />
            <em>{{ photo.filename }}</em>
          </div>
        </masonry>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import mixin from '@/mixins'
import moment from 'moment-timezone'
import axios from 'axios'
export default {
  mixins: [mixin],
  data () {
    return {
      shooting: null,
      single: false,
      index: null,
      loading: false
    }
  },
  computed: {
    formatedDate () {
      return moment(this.shooting.date, 'DD-MM-YYYY').format('D MMM YYYY')
    }
  },
  methods: {
    changeView (single, index, obj) {
      if (window.innerWidth > 768) {
        if (!single) {
          this.single = !single
          this.index = index
        } else {
          this.single = !single
          this.index = null
        }
      } else if (navigator.share && obj) {
        navigator.share({
          title: obj.photo.filename,
          url: this.getPhotoPath(obj.shooting.id, obj.photo.id)
        }).catch((err) => console.error(err))
      }
    }
  },
  mounted () {
    this.loading = true
    axios.get('client/shooting/' + this.$route.params.id)
      .then((shooting) => {
        this.loading = false
        this.shooting = shooting.data
      })
      .catch(() => {
        this.$router.push({ name: 'Home' })
      })
  }
}
</script>

<style lang="scss" scoped>
  .el-container {
    min-height: 100vh;
    position: relative;
    background-color: #ffffff;
  }

  .el-aside {
    background-color: #000000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    min-height: 100vh;

    h2 {
      color: #fff;
      font-size: 22px;
      text-transform: uppercase;
      margin-bottom: 5px;
      margin-top: 0;
    }

    h3 {
      color: #dedede;
      margin: 0;
      text-transform: uppercase;
      font-weight: 300;
      max-width: 140px;
      line-height: 25px;
    }

    .logo {
      max-width: 200px;
      display: block;
      margin: 0 auto 40px;
      cursor: pointer;
    }

    .el-button + .el-button {
      margin-left: 0 !important;
    }
    .el-button {
      text-align: left;
    }

    .button-container {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      .button-big {
        color: #fff !important;
        cursor: pointer;

        &.download {
          color: #C10404 !important;
          font-weight: 600;
        }
      }

      a {
        margin-top: 10px;
      }
    }

    .single-buttons {
      position: absolute;
      bottom: 15px;
      left: 15px;

      .single-buttons__date {
        font-size:22px;
        text-transform: uppercase;
      }

      .single-photo-nav {
        padding: 16px 0;

        .el-button {
          padding: 0;
          text-transform: uppercase;
          font-size: 18px;
        }
        .el-button.is-disabled {
          color: grey !important;
        }

        .prev {
          margin-right: 16px;
          color: #fff;
        }
        .next {
          color: #fff;
        }
      }

      .single-buttons__show-all {
        padding: 0;
        font-size: 18px;
        text-transform: uppercase;
        color: #fff;
      }
    }
  }

  .el-main {
    margin-left: 340px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .masonry-img-container {
      margin-bottom: 11px;
    }

    .single-img-container {
      max-width: 900px;
      max-height: 900px;

      .el-image {
        max-width: 100%;
        max-height: 90vh;

        .el-image__inner {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    em {
      font-size: 80%;
    }
  }

  @media screen and (max-width:500px) {
    .el-aside {
      padding: 20px 20px 40px;

      h2 {
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width:768px) {
    .el-container {
      flex-direction: column;
      min-height:unset;
    }

    .el-aside {
      position: relative;
      min-height: 0;
      width: 100%!important;
      padding-bottom: 10px;

      .single-buttons {
        position: static;
      }

      .logo {
        position: absolute;
        right: 20px;
        max-width: 120px;
        margin-bottom: 0;
      }
    }

    .el-main {
      margin: 0;
    }

    .button-container{
      margin-top: 10px!important;
    }
  }
</style>

<style lang="scss">
  .single-img-container {
    .el-image {
      -webkit-box-shadow: 0px 0px 77px -30px rgba(0,0,0,0.26);
      box-shadow: 0px 0px 77px -30px rgba(0,0,0,0.26);
      .el-image__inner {
        max-width:900px;
        max-height: 90vh;
      }
    }
  }

  .user .masonry-img-container:hover {
    .el-image {
      .el-image__inner {
        transform: scale(1.05);
        transition: all .3s ease-in-out;
      }
    }
    opacity: .9;
    transition: opacity .2s ease-in-out;
  }
</style>
