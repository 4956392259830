<template>
  <el-main>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-input
          v-model="search"
          prefix-icon="el-icon-search"
          placeholder="Search something"
          clearable
        />
      </el-col>
      <el-col :span="12" style="text-align: right">
        <el-button
          type="primary"
          class="button-create"
          icon="el-icon-plus"
          @click="create = true"
        >
          Create folder
        </el-button>
      </el-col>
    </el-row>

    <el-table
      v-loading="loading"
      :data="data"
      :default-sort="{prop: 'date', order: 'descending'}"
    >
      <el-table-column prop="date" label="Date" sortable class="mobile" width="100">
        <template slot-scope="scope">
          {{ formatedDate(scope.row.date) }}
        </template>
      </el-table-column>

      <el-table-column label="Photos" align="center" sortable prop="photos" v-if="!isTablette" width="150">
        <template slot-scope="scope">
          {{ scope.row.photos }}
        </template>
      </el-table-column>

      <el-table-column label="Name">
        <template slot-scope="scope">
          <el-button type="text" @click.prevent="$router.push({ name: 'AdminPrivateView', params: { id: scope.row.id } })">
            {{ scope.row.identifier }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="create" top="2vh">
      <AdminCreate :is-public="false" @close="close" />
    </el-dialog>
  </el-main>
</template>

<script>
import mixin from '@/mixins'
import moment from 'moment-timezone'
import axios from 'axios'
import { mapState } from 'vuex'
import AdminCreate from '../../components/Create.vue'

export default {
  mixins: [mixin],
  components: { AdminCreate },
  name: 'AdminFolders',
  data () {
    return {
      folders: [],
      search: null,
      create: false,
      loading: false
    }
  },
  computed: {
    isMobile () {
      return window.screen.width < 430
    },
    isTablette () {
      return window.screen.width < 766
    },
    data () {
      if (this.search) {
        const regex = new RegExp(this.search, 'i')
        return this.folders.filter((s) => s.identifier.match(regex))
      }
      return this.folders
    },
    ...mapState(['token', 'user'])
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      this.loading = true
      axios.get('admin/folders').then((folders) => {
        this.folders = folders.data
        this.loading = false
      }).catch(() => this.$router.push('/login'))
    },
    close () {
      this.create = false
      this.load()
    },
    formatedDate (date) {
      return moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY')
    }
  },
  watch: {
    '$route.name' () {
      this.load()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../theme.scss";

.el-header {
  background-color: #000;

  .el-menu {
    border-bottom: 0;
    height: 90px;

    .el-menu-item {
      height: 90px;
      line-height: 90px;
      font-size: 20px;

      &.is-active {
        background-color: $--color-primary !important;
        color: #fff !important;
      }
    }
  }
  .el-row {
    .el-col {
      text-align: center;

      img {
        max-height: 90px;
      }

      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;

        .el-button {
          margin-top: 25px
        }
      }
    }
  }
}

.el-main {
  .el-select + .el-button {
    margin-left: 10px;
  }
}

.filters {
  margin-top: 20px;
}

@media screen and (max-width: 765px){
}
</style>
