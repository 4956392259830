import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Admin from '@/views/Admin/Admin.vue'
import HomeUser from '@/views/User/Home.vue'
import AdminView from '@/views/Admin/AdminView.vue'
import AdminPrivateView from '@/views/Admin/AdminPrivateView.vue'
import AdminUsers from '@/views/Admin/AdminUsers.vue'
import AdminFolders from '@/views/Admin/AdminFolders.vue'
import Login from '@/views/Admin/Login.vue'
import Contact from '@/views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: [{
      path: 'view/:id',
      name: 'AdminView',
      component: AdminView
    }, {
      path: 'private/:id',
      name: 'AdminPrivateView',
      component: AdminPrivateView
    }, {
      path: 'folders',
      name: 'AdminFolders',
      component: AdminFolders
    }, {
      path: 'users',
      name: 'AdminUsers',
      component: AdminUsers
    }]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/shooting/:id',
    name: 'HomeUser',
    component: HomeUser
  }
]

const router = new VueRouter({
  routes
})

export default router
